/// <reference types='gravity-client' />
import { DataStore } from 'flux-store';
import { AbstractAuthentication } from 'flux-connection';
import { AppConfig, AppPlatform, ContainerEnv, IModalOptions,
         ModalController, StateService, Tracker } from 'flux-core';
import { Injectable, Inject } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { DesktopAuthWindow } from '../ui/auth/desktop-auth-window.cmp';
import { OnboardingPage, OnboardingWindow } from '../ui/auth/onboarding-window.cmp';

/**
 *  This enum defines all possibles states authentication can be in.
 */
export enum AuthenticationStatus {

    /**
     * An authentication exists in the system, is NOT expired, and meets
     * all validation criterea.
     */
    AUTHENTICATED,

    /**
     * An authentication token exists in the system but is expired.
     */
    EXPIRED,

    /**
     * An authentication token is not present in the system.
     */
    NOT_AUTHENTICATED,
}

/**
 * This is the authentication service which manages all authentication related
 * functionality in nucleus. It performs authentication checks, sets authentication
 * states, manages showing and hiding auth widgets, sets the current user and
 * manages login and logout scenarios.
 */
@Injectable()
export class Authentication extends AbstractAuthentication {

    /**
     * Constructor.
     * Will initialize the authentication service.
     * @param state Auth state servuce
     */
    constructor(
        @Inject( StateService ) protected state: StateService<any, any>,
        @Inject( DataStore ) protected datastore: DataStore,
        @Inject( ModalController ) protected modalController: ModalController,
        protected env: ContainerEnv,
    ) {
        super()/* istanbul ignore next */;
    }

    /******************************************************
     * Token related functions that make use of gravity API
     ******************************************************/

    /**
     * Returns the current authentication token
     * Uses the gravity client library to check token existence.
     * @return string representation of the gravity token
     */
    public get token(): string {
        return window.gravity.client.getCookie();
    }

    /**
     * Replaces the gravity cookie.
     * NOTE: the app probably needs to be reloaded after doing this.
     * TODO: check whether it is safe to allow setting the auth cookie.
     */
    public set token( token: string ) {
        window.gravity.client.setCookie( token );
    }

    /**
     * Returns if the user is authenticated or not.
     * Uses the gravity client library to check for a valid auth token.
     * @return true if authentication exists and valid, false otherwise
     */
    public get isAuthenticated(): boolean {
        return window.gravity.client.hasValidSession();
    }

    /**
     * Fetches the user id associated with the current gravity token.
     * @return  user id as string
     */
    public get currentUserId(): string {
        if ( this.token ) {
            return window.gravity.client.getCurrentUserID();
        }
    }

    /**
     * Return granted platform for this token
     * @return string of apps.
     * ['embedded', 'online', 'desktop']
     */
    private get grantedPlatforms(): string {
        if ( this.token ) {
            return window.gravity.client.getGrantedPlatform();
        }
    }

    /**
     * Initializes the authentication service.
     * Bootstraps the gravity client library and initializes the
     * authentication and current user states.
     */
    public initialize() {
        this.updateStates();
    }

    /**
     * Checks the authentication status by alanysing the gravity token.
     * Updates the Authentication and CurrentUser states based on token
     * status.
     * @return - current state of Authentication
     */
    public check(): AuthenticationStatus {
        this.updateStates();
        return this.state.get( AuthenticationStatus );
    }

    /**
     * Returns true if the given app is authorized for
     * this token.
     * NOTE: If the claim 'apps' not available in the token
     * it will return true. This is because we have two places
     * where the gravity token get generated.
     *  1. Gravity -> from phoenix authentication page. At the
     *     moment this does not contain 'apps' claim.
     *  2. Service-auth -> for external users to access the app
     *     in embedded mode.
     * @param app - AppPlatform
     * @returns - boolean
     */
    public isAppPlatformAuthorized( app: AppPlatform ): boolean {
        if ( this.grantedPlatforms ) {
            const apps = this.grantedPlatforms.split( ',' );
            return apps.includes( app );
        }
        return true;
    }

    /**
     * Checks whether the given token belongs to the same user or not.
     * Returns false if user is not authenticated or token is invalid.
     * TODO: enable after fixing gravity client issue
     */
    // public isSameUserToken( token: string ): boolean {
    //     if ( !this.isAuthenticated ) {
    //         return false;
    //     }
    //     const tokenUserId = this.getTokenUserId( token );
    //     if ( !tokenUserId ) {
    //         return false;
    //     }
    //     return this.currentUserId === tokenUserId;
    // }

    /******************************************************
     * Showing and hiding widgets
     ******************************************************/

     /**
      * Shows the login widget.
      * Updates the authentication and current user states after the
      * authentication result.
      * Hides the widget upon successful authentication.
      * @return An observable that emits the authentication status.
      *         and throw an error when authentication has failed (or
      *         if login widget was closed without authenticating )
      */
    public showLogin(): Observable<AuthenticationStatus> {
        const authWindowType: any = this.env.isDesktop ? DesktopAuthWindow : OnboardingWindow;
        const options: IModalOptions = this.env.isDesktop ?
            { inputs: { appLevel: true }} : { inputs: { page: OnboardingPage.SignIn, appLevel: true }};
        return this.modalController.show( authWindowType, options ).pipe(
            tap({
                complete: () => {
                    this.updateStates();
                    this.hideLogin();
                },
            }),
            map(() => this.state.get( AuthenticationStatus )),
        );
    }

     /**
      * Shows the sign up widget.
      * Updates the authentication and current user states after the
      * authentication result.
      * Hides the widget upon successful authentication.
      * @param inputs extra inputs pass to OnboardingWindow component
      * @return An observable that emits the authentication status.
      *         and throw an error when authentication has failed (or
      *         if sign up widget was closed without authenticating )
      */
    public showSignUp( inputs: Object = {}): Observable<AuthenticationStatus> {
        return this.modalController.show( OnboardingWindow,
            { inputs: { ...inputs, page: OnboardingPage.SignUp, appLevel: true }}).pipe(
                tap({
                    complete: () => {
                        this.updateStates();
                        this.hideLogin();
                    },
                }),
                map(() => this.state.get( AuthenticationStatus )),
            );
    }

     /**
      * Shows the demo authentication dialog box.
      * This flow can be redirect to sign / signin for some scenarios.
      * 1. Demo users who trying create demo account again will redirect to sign up.
      * 2. If user who trying to create demo account have creately stranded account.
      *   They will be redirect to sign in page.
      * Updates the authentication and current user states after the
      * authentication result.
      * Hides the widget upon successful authentication.
      * @param email email (if any) of the demo user to pass to OnboardingWindow
      * @return An observable that emits the authentication status.
      *         and throw an error when authentication has failed (or
      *         if sign up widget was closed without authenticating )
      */
    public showDemoAuthenticationFlow( email?: string ) {
        return this.modalController.show( OnboardingWindow, {
            inputs: {
                page: OnboardingPage.DemoEmailVerify,
                email,
            },
        }).pipe(
            tap({
                complete: () => {
                    this.updateStates();
                    this.hideLogin();
                },
            }),
            map(() => this.state.get( AuthenticationStatus )),
        );
    }

    /**
     * Tries to authenticate using the gravity refresh token.
     * If not success show sign in window.
     * Updates the authentication and current user states after the
     * authentication completes.
     * @return An observable that emits the authentication status.
     *         and throw an error when authentication has failed (or
     *         if login widget was closed without authenticating )
     */
   public authenticate(): Observable<AuthenticationStatus> {
        Tracker.track( 'authenticate.using_refresh_token' );

        if ( this.state.get( 'ApplicationIsEmbedded' )) {
            const ssIframe = document.getElementById( 'ssIFrame_gravity' );
            if ( ssIframe ) {
                document.body.removeChild( ssIframe );
            }
        }
        return from( window.gravity.client.authenticate()).pipe(
            switchMap( successful => {
                if ( successful ) {
                    this.updateStates();
                    return of( this.state.get( AuthenticationStatus ));
                }
                return this.showLogin();
            }),
        );
   }

   /**
    * Tries to authenticate using the oti token.
    * Updates the authentication and current user states after the
    * authentication success.
    * @return A promise that resolved to authentication status.
    *         and throw an error when authentication has failed (or
    *         if login widget was closed without authenticating )
    */
    public authenticateWithOTI( oti: string, updateStates: boolean = true ): Promise<boolean> {
        // window.gravity.client as any: because type definitions are not up to date for gravity client
        // TODO remove as any  part once the gravity client is updated.
        const promise: Promise<boolean> = ( window.gravity.client as any ).authenticateWithOTI( oti );
        return updateStates ? promise.then(( successful: boolean ) => {
            if ( successful ) {
                this.updateStates();
            }
            return successful;
        }) : promise;
    }

    /**
     * This method opens the login page in browser and waits for user to login in.
     * If user not logs into browser within given timeout this will return a observable resolved to false.
     * @param timeout number of seconds to wait before OTI expires
     * @param interval number of seconds to check if the user has logged in with the OTI
     */
    public loginWithOTI( timeout: number = 300, interval: number = 2 ): Observable<boolean> {
        Tracker.track( 'login with oti' );
        const oti = this.generateOTI();

        // openExternal is only set in desktop app.
        ( window as any ).openExternal( AppConfig.get( 'SITE_URL' ) + 'login/?oti=' + oti );

        return new Observable( observer => {
            let intervalId: any;

            const complete = ( success: boolean ) => {
                clearInterval( intervalId );
                observer.next( success );
                observer.complete();
            };

            const timeoutId = setTimeout(() => {
                complete( false );
            }, timeout * 1000 );

            let count = 0;
            intervalId = setInterval(() => {
                if ( !document.hasFocus() && count < 10 ) {
                    count++;
                    return;
                }
                count = 0;
                this.authenticateWithOTI( oti, false ).then( success => {
                    if ( success ) {
                        clearTimeout( timeoutId );
                        complete( true );
                    }
                });
            }, interval * 1000 );
        });
    }

    /**
     * Logs out the current user and clears the data in the browser database
     */
    public logOut(): Observable<any> {
        // FIXME - temp code
        localStorage.removeItem( 'creately_limited_time_offer' );
        window.gravity.client.logOut();
        this.updateStates();
        return this.datastore.drop();
    }

    protected generateOTI(): string {
        return Math.random().toString( 36 ).substr( 2 ) + '.' +
            Math.floor( Date.now() / 1000 ).toString( 36 );
    }

    /**
     * Hides any gravity widgets that are showing.
     */
    protected hideLogin() {
        this.modalController.hide();
    }

    /******************************************************
     * Setting and updating states
     ******************************************************/

    /**
     * Updates the current user and authentication states based on
     * gravity token values.
     */
    protected updateStates() {
        this.updateAuthState();
        this.updateUserState();
    }

    /**
     * This function derives the authentication status based on the
     * availability and validy of the gravity token.
     * For an explanation of the possible authentication states
     * the system ca n be in, please refer to {@link AuthenticationStatus} enum.
     */
    protected updateAuthState() {
        if ( this.isAuthenticated ) {
            this.state.set( AuthenticationStatus, AuthenticationStatus.AUTHENTICATED );
        } else {
            if ( !this.token ) {
                this.state.set( AuthenticationStatus, AuthenticationStatus.NOT_AUTHENTICATED );
            } else {
                this.state.set( AuthenticationStatus, AuthenticationStatus.EXPIRED );
            }
        }
    }

    /**
     * Updates the current user state based on the user information
     * extracted from the token
     */
    protected updateUserState() {
        if ( this.currentUserId ) {
            this.state.set( 'CurrentUser', this.currentUserId );
        } else {
            if ( this.state.has( 'CurrentUser' )) {
                this.state.set( 'CurrentUser', '' );
            }
        }
    }

    /**
     * Returns the user id for the given token.
     * TODO: add a method on gravity client to validate and parse tokens.
     * TODO: enable after fixing gravity client issue
     */
    // private getTokenUserId( token: string ): string {
    //     // TODO: implement this method
    //     return null;
    // }
}

/**
 * StateService representation for authentication state
 */
export class AuthenticationStateService extends StateService< typeof AuthenticationStatus, AuthenticationStatus > {}
