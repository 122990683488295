<div class="col-menu-item-container" [ngClass]="{ 'expanded': active, 'collapsed': !active }">
    <div class="col-menu-item-heading fx-no-selection fx-margin-bottom-5 btn-list-item">
        <div class="col-menu-item-heading-inner" (click)="toggle($event)">
            <div class="col-menu-item-icon" [hidden]="(icon || !collapsable)">
                <svg class="nu-icon nu-icon-small nu-ic-close-thin fx-margin-left-5">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-collapse-fill"></use>
                </svg>
            </div>
            <div class="body col-menu-item-heading-title fx-ellipsis">{{ title }}</div>
            <div *ngIf="isPremium && (isFreeOrDemoUser|async)">
                <div class="premium btn-secondary" (click)="openUpgradeWindow()">
                    <div class="icon">
                        <svg class="nu-icon">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-premium-crown"></use>
                        </svg>
                    </div>
                    <div class="fx-margin-left-5 caption caption--sm caption--bold">{{ translate.get('LABELS.PREMIUM') | async}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-menu-item-sub-label" *ngIf="( subText | async ) as text">
        {{ text }}
    </div>

    <div class="col-menu-item-content">
        <div class="fx-invisible-item" #componentContainer></div>
    </div>
</div>