import { DataType } from 'flux-definition';
import { Injectable } from '@angular/core';
import { Command } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { sortBy } from 'lodash';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';

@Injectable()
@Command()
export class ModifyTable extends AbstractDiagramChangeCommand {

    /**
     * Command input data format
     */
    public data: {
        tableId: string,
        columns: [],
        rows: [],
        bounds: { x: number, y: number },
    };

    public prepareData() {
        this.resultData = { ...this.data };

        const id = this.data.tableId;
        const value = this.data;
        const columns = this.data.columns || [];
        const rows = this.data.rows || [];

        let template = '';
        if ( columns.length > 0  && rows.length === 0 ) {
            template = 'columns';
        } else if ( columns.length === 0  && rows.length > 0 ) {
            template = 'rows';
        } else if ( columns.length > 0  && rows.length > 0 ) {
            template = 'columns-rows';
        }

        const table = this.changeModel.shapes[id] as ShapeModel;

        table.x = value.bounds.x;
        table.y = value.bounds.y;

        const layoutReg: any = Object.values( table.containerRegions )
            .find(( region:  any )  => region.layoutingId );

        const layoutingId = layoutReg?.layoutingId || 'elk-rectpacking';
        if ( template === 'columns' ) {
            // map column name to region id
            const cellsMap = {};

            // Prepare table columns
            const _cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            const sortedByCols = sortBy( _cells, cell => cell.columnId ).reverse();
            const noOfCols = sortedByCols[ 0 ].columnId;
            const requiredCols = columns.length - noOfCols;
            if ( requiredCols > 0 ) {
                for ( let i = 0; i < requiredCols; i++ ) {
                    ( table as any ).addColumnRight( table, this.changeModel );
                }
            } else if ( requiredCols < 0 ) {
                for ( let i = 0; i < Math.abs( requiredCols ); i++ ) {
                    ( table as any ).removeColumnRight( table, this.changeModel );
                }
            }

            // Update columns name and cellsMap
            const cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            columns.forEach(( colText: any, i ) => {
                const sortedByRows = sortBy( cells, cell => cell.rowId );
                const topmostRowId = sortedByRows[0].rowId;
                const topRowCells = sortedByRows.filter( cell => cell.rowId === topmostRowId );
                const _secondRowCells = sortedByRows.filter( cell => cell.rowId === topmostRowId + 1 );
                const secondRowCells = sortBy( _secondRowCells, cell => cell.columnId );
                const headCell = topRowCells.find( cell => cell.columnId === i + 1 );
                cellsMap[ colText ] = secondRowCells[i];
                const styleRefShape = this.changeModel.getLayoutingShapesInRegion(
                    table.id, secondRowCells[i].id,
                )?.[0];
                cellsMap[ colText ].styleRefShape = styleRefShape;
                const region = table.containerRegions[ secondRowCells[i].id ];
                ( region as any ).layoutingId  = layoutingId;
                if ( colText ) {
                    table.texts[ headCell.id ].content = [{
                        ...table.texts[ headCell.id ].content[0],
                        text: colText,
                    }];
                }
            });
            this.resultData.cellsMapVertical = cellsMap;
        }

        if ( template === 'rows' ) {
            const cellsMap = {};

            // Prepare table rows
            const _cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            const sortedByRows = sortBy( _cells, cell => cell.rowId ).reverse();
            const noOfRows = sortedByRows[ 0 ].rowId;
            const requiredRows = rows.length - noOfRows;
            if ( requiredRows > 0 ) {
                for ( let i = 0; i < requiredRows; i++ ) {
                    ( table as any ).addRowBelow( table, this.changeModel );
                }
            } else if ( requiredRows < 0 ) {
                for ( let i = 0; i < Math.abs( requiredRows ); i++ ) {
                    ( table as any ).removeRowBelow( table, this.changeModel );
                }
            }

            const cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            rows.forEach(( rowText: any, i ) => {
                const sortedByCols = sortBy( cells, cell => cell.columnId );
                const leftmostColId = sortedByCols[0].columnId;
                const leftColCells = sortedByCols.filter( cell => cell.columnId === leftmostColId );
                const _secondColCells = sortedByCols.filter( cell => cell.columnId === leftmostColId + 1 );
                const secondColCells = sortBy( _secondColCells, cell => cell.rowId );
                const headCell = leftColCells.find( cell => cell.rowId === i + 1 );
                cellsMap[ rowText ] = secondColCells[i];
                const styleRefShape = this.changeModel.getLayoutingShapesInRegion(
                    table.id,
                    secondColCells[i].id,
                )?.[0];
                cellsMap[ rowText ].styleRefShape = styleRefShape;
                const region = table.containerRegions[ secondColCells[i].id ];
                ( region as any ).layoutingId  = layoutingId;
                if ( rowText ) {
                    table.texts[ headCell.id ].content = [{
                        ...table.texts[ headCell.id ].content[0],
                        text: rowText,
                    }];
                }
            });
            this.resultData.cellsMapHorizontal = cellsMap;
        }

        if ( template === 'columns-rows' ) {

            // Prepare table columns
            const _cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            const sortedByCols = sortBy( _cells, cell => cell.columnId ).reverse();
            const noOfCols = sortedByCols[ 0 ].columnId - 1;
            const requiredCols = columns.length - noOfCols;
            if ( requiredCols > 0 ) {
                for ( let i = 0; i < requiredCols; i++ ) {
                    ( table as any ).addColumnRight( table, this.changeModel );
                }
            } else if ( requiredCols < 0 ) {
                for ( let i = 0; i < Math.abs( requiredCols ); i++ ) {
                    ( table as any ).removeColumnRight( table, this.changeModel );
                }
            }

            // Prepare table rows
            const sortedByRows = sortBy( _cells, cell => cell.rowId ).reverse();
            const noOfRows = sortedByRows[ 0 ].rowId - 1;
            const requiredRows = rows.length - noOfRows;
            if ( requiredRows > 0 ) {
                for ( let i = 0; i < requiredRows; i++ ) {
                    ( table as any ).addRowBelow( table, this.changeModel );
                }
            } else if ( requiredRows < 0 ) {
                for ( let i = 0; i < Math.abs( requiredRows ); i++ ) {
                    ( table as any ).removeRowBelow( table, this.changeModel );
                }
            }

            const cells = Object.values( table.data )
                .filter(( data:  any ) =>
                    data.type === DataType.CHILD_SHAPE )
                .map(( data:  any )  => data.value );
            columns.forEach(( colText: any, i ) => {
                const sortedRows = sortBy( cells, cell => cell.rowId );
                const topmostRowId = sortedRows[0].rowId;
                const topRowCells = sortedRows.filter( cell => cell.rowId === topmostRowId );
                const headCell = topRowCells.find( cell => cell.columnId === i + 2 );
                if ( colText ) {
                    table.texts[ headCell.id ].content = [{
                        ...table.texts[ headCell.id ].content[0],
                        text: colText,
                    }];
                }
            });

            rows.forEach(( rowText: any, i ) => {
                const sortedCols = sortBy( cells, cell => cell.columnId );
                const leftmostColId = sortedCols[0].columnId;
                const leftColCells = sortedCols.filter( cell => cell.columnId === leftmostColId );
                const headCell = leftColCells.find( cell => cell.rowId === i + 2 );
                if ( rowText ) {
                    table.texts[ headCell.id ].content = [{
                        ...table.texts[ headCell.id ].content[0],
                        text: rowText,
                    }];
                }
            });

            const cellsMapVerticalHorizontal = {};
            columns.forEach(( colText: any, i ) => {
                const columnId = i + 2;
                cellsMapVerticalHorizontal[ colText ] = {};
                rows.forEach(( rowText: any, j ) => {
                    const rowId = j + 2;
                    const cell = cells.find( c => c.columnId === columnId && c.rowId === rowId );
                    const region = table.containerRegions[ cell.id ];
                    ( region as any ).layoutingId  = layoutingId;
                    cellsMapVerticalHorizontal[ colText ][ rowText ] = cell;
                    const styleRefShape = this.changeModel.getLayoutingShapesInRegion( table.id, cell.id )?.[0];
                    cellsMapVerticalHorizontal[ colText ][ rowText ].styleRefShape = styleRefShape;
                });
            });
            this.resultData.cellsMapVerticalHorizontal = cellsMapVerticalHorizontal;
        }

        Object.keys( table.children ).forEach( childShapeId => {
            delete this.changeModel.shapes[ childShapeId ];
        });
        table.children = {};
        Object.keys( table.containerRegions ).forEach( regId => {
            table.containerRegions[ regId ].shapes = {};
        });

    }

}

Object.defineProperty( ModifyTable, 'name', {
    value: 'ModifyTable',
});
