import { IPoint2D, IRectangle, ITransform } from 'flux-definition';
import { Matrix } from './matrix';
import { Point } from './point';
import { Polygon } from './polygon';
import { Position } from './position';

/**
 * Rectangle
 * class the represents a ClientRect defined
 * by and MDN. It contains additioan math functions relating
 * to geometry and rectangles. Some functionality were extracted
 * from the google closure Library.
 *
 * @author hiraash
 * @since 2016-08-07
 */
export class Rectangle implements ClientRect, IRectangle {
    /**
     * Creates a Rectangle instance using an IRectangle
     */
    public static from( rect: IRectangle ): Rectangle {
        return new Rectangle( rect.x, rect.y, rect.width, rect.height );
    }

    /**
     * Creates a Rectangle object using the given ClientRect instance
     */
    public static fromClientRect( rectangle: ClientRect ): Rectangle {
        if ( rectangle ) {
            return new Rectangle( rectangle.left, rectangle.top, rectangle.width, rectangle.height );
        }
    }

    /**
     * Returns a rectangle which will contain all given points.
     * @param points points to get the bound rectangle for
     */
    public static withPoints( ...points: IPoint2D[]): Rectangle {
        if ( !points.length ) {
            return null;
        }
        const min = Point.min( ...points );
        const max = Point.max( ...points );
        return new Rectangle( min.x, min.y, max.x - min.x, max.y - min.y );
    }

    /**
     * Properties from ClientRect
     */
    public left: number;
    public top: number;
    public width: number;
    public height: number;

    constructor( x: number = 0, y: number = 0, w: number = 0, h: number = 0 ) {
        this.left = x;
        this.top = y;
        this.width = w;
        this.height = h;
    }

    public get x(): number {
        return this.left;
    }
    public set x( v: number ) {
        this.left = v;
    }

    public get y(): number {
        return this.top;
    }
    public set y( v: number ) {
        this.top = v;
    }

    public get right(): number {
        return this.width + this.left;
    }

    public set right( v: number ) {
        this.width =  v - this.left;
    }

    public get bottom(): number {
        return this.height + this.top;
    }

    public set bottom( v: number ) {
        this.height = v - this.top ;
    }

    public get centerX(): number {
        return this.left + ( this.width / 2 );
    }

    public get centerY(): number {
        return this.top + ( this.height / 2 );
    }

    /**
     * The area of the rectangle in square pixels
     */
    public get area(): number {
        return this.width * this.height;
    }

    /**
     * Returns the same instance
     */
    public toClientRect(): ClientRect {
        return this;
    }

    /**
     * Creates a polygon with rectangle points
     */
    public toPolygon(): Polygon {
        return new Polygon([
            { x: this.x, y: this.y },
            { x: this.x, y: this.y + this.height },
            { x: this.x + this.width, y: this.y + this.height },
            { x: this.x + this.width, y: this.y },
        ]);
    }

    /**
     * Borrowed from goog.math.Rect (Google Closure Library)
     * Returns the intersection of two rectangles. Two rectangles intersect if they
     * touch at all, for example, two zero width and height rectangles would
     * intersect if they had the same top and left.
     * @param rect A Rectangle.
     * @return A new intersection rect (even if width and height
     *     are 0), or null if there is no intersection.
     */
    public intersection( rect: Rectangle ): Rectangle {
        // There is no nice way to do intersection via a clone, because any such
        // Clone might be unnecessary if this function returns null.  So, we duplicate
        // Code from above.

        const x0 = Math.max( this.left, rect.left );
        const x1 = Math.min( this.left +  this.width, rect.left + rect.width );

        if ( x0 <= x1 ) {
            const y0 = Math.max( this.top, rect.top );
            const y1 = Math.min( this.top +  this.height, rect.top + rect.height );

            if ( y0 <= y1 ) {
            return new Rectangle( x0, y0, x1 - x0, y1 - y0 );
            }
        }
        return null;
    }

    /**
     * Borrowed from goog.math.Rect (Google Closure Library)
     * Computes the difference regions between two rectangles. The return value is
     * an array of 0 to 4 rectangles defining the remaining regions of this
     * rectangle after the given rectangle has been subtracted.
     * @param rect A Rectangle to subtract.
     * @return An array with 0 to 4 rectangles which
     *     together define the difference area of rectangle a minus rectangle rect.
     */
    public difference( rect: Rectangle ): Array<Rectangle> {
        const intersection: Rectangle = this.intersection( rect );
        if ( !intersection || !intersection.height || !intersection.width ) {
            return [ this ];
        }

        const result = [];

        let top = this.top;
        let height = this.height;

        const ar = this.left + this.width;
        const ab = this.top + this.height;

        const br = rect.left + rect.width;
        const bb = rect.top + rect.height;

        // Subtract off any area on top where A extends past B
        if ( rect.top > this.top ) {
            result.push( new Rectangle( this.left, this.top, this.width, rect.top - this.top ));
            top = rect.top;
            // If we're moving the top down, we also need to subtract the height diff.
            height -= rect.top - this.top;
        }
        // Subtract off any area on bottom where A extends past B
        if ( bb < ab ) {
            result.push( new Rectangle( this.left, bb, this.width, ab - bb ));
            height = bb - top;
        }
        // Subtract any area on left where A extends past B
        if ( rect.left > this.left ) {
            result.push( new Rectangle( this.left, top, rect.left - this.left, height ));
        }
        // Subtract any area on right where A extends past B
        if ( br < ar ) {
            result.push( new Rectangle( br, top, ar - br, height ));
        }

        return result;
    }

    /**
     * Adds a padding of given value to this
     * rectangle. The padding will expand the rectangle
     * and if minus value, will minimize the rectangle.
     *
     * @param value the padding in pixels to be added.
     */
    public pad( value: number | Array<number> ): Rectangle {
        if ( value instanceof Array ) {
            this.top -= value[0];
            this.left -= value[3];
            this.width += value[3] + value[1];
            this.height += value[0] + value[2];
            return this;
        } else {
            this.top -= value;
            this.left -= value;
            this.width += 2 * value;
            this.height += 2 * value;

            return this;
        }
    }

    /**
     * This method rotates the current rectangle to the given value
     * and then sets itself the bounds around the rotated rectangle.
     * The result is always a rectanle but absorbs the rotated rectangle.
     *
     * Ex: inside is the rotated rectangle. The resulting bound value is the
     * ouside rectangle.
     *  ___________
     *  |   *     |
     *  |  *     *|
     *  | *     * |
     *  |*     *  |
     *  |_____*___|
     * @param angle The angle to rotate to.
     */
    public rotate( angle: number ): Rectangle {
        const leftTop = new Point( 0, 0 );
        const rightTop = new Point( this.width, 0 ).rotate( angle );
        const leftBottom = new Point( 0, this.height ).rotate( angle );
        const rightBottom = new Point( this.width, this.height ).rotate( angle );

        const topLeft = leftTop.min( rightTop ).min( leftBottom ).min( rightBottom );
        const bottomRight = leftTop.max( rightTop ).max( leftBottom ).max( rightBottom );

        this.x += topLeft.x;
        this.y += topLeft.y;
        this.width = bottomRight.x - topLeft.x;
        this.height = bottomRight.y - topLeft.y;

        return this;
    }

    /**
     * This method transfomes the current rectangle to the given values
     * and then sets itself the bounds around the transformed rectangle.
     * The result is always a rectanle but absorbs the transformed rectangle.
     *
     * Ex: inside is the transformed rectangle. The resulting bound value is the
     * ouside rectangle.
     *  ___________
     *  |   *     |
     *  |  *     *|
     *  | *     * |
     *  |*     *  |
     *  |_____*___|
     * @param t The transformation changes to apply
     */
    public transform( t: ITransform ): Rectangle {
        const matrix = Matrix.fromTransform( t );

        const leftTop = matrix.transform( this.left, this.top );
        const rightTop = matrix.transform( this.right, this.top );
        const leftBottom = matrix.transform( this.left, this.bottom );
        const rightBottom = matrix.transform( this.right, this.bottom );

        const topLeft = leftTop.min( rightTop ).min( leftBottom ).min( rightBottom );
        const bottomRight = leftTop.max( rightTop ).max( leftBottom ).max( rightBottom );

        this.x = topLeft.x;
        this.y = topLeft.y;
        this.width = bottomRight.x - topLeft.x;
        this.height = bottomRight.y - topLeft.y;

        return this;
    }

    /**
     * Returns a new instance with same values
     * as this instance.
     */
    public clone(): Rectangle {
        return Rectangle.fromClientRect( this );
    }


    /**
     * This method absorbs the input rect and updates the bounds
     * The resulting bound values can be changed depending on the input rect bounds
     * @param Rectangle
     * @returns This instance
     */
    public absorb( rect: Rectangle ): Rectangle {
        const xMax: number = Math.max( rect.right, this.right );
        const yMax: number = Math.max( rect.bottom, this.bottom );

        const xMin: number = Math.min( rect.left, this.left );
        const yMin: number = Math.min( rect.top, this.top );

        this.top = yMin;
        this.left = xMin;
        this.width = xMax - xMin;
        this.height = yMax - yMin;

        return this;
    }

    /**
     * Returns true if this rectangle fully encloses the described rectangle.
     * @param Rectangle
     * @returns boolean
     */
    public contains( rect: Rectangle ): boolean {
        return ( rect.x >= this.x
            && rect.x + rect.width <= this.x + this.width
            && rect.y >= this.y
            && rect.y + rect.height <= this.y + this.height );
    }

    /**
     * This method can be used to determine the bounds when placing this rectangle
     * inside another rectangle.
     * Current rectangle is scaled up or down to fit the destination rectangle
     * while maintaining its original aspect ratio. This method does not modify the
     * current rectangle - it simply returns a new rectangle with the desired bounds.
     * @param destination - the rectangle which this rectangle will be placed in
     * @return Bounds of the rectangle so that it can be fittend inside the destination.
     */
    public boundsToFit ( destination: IRectangle ): Rectangle {
        const scale =  Math.min(( destination.width / this.width ), ( destination.height / this.height ));
        const scaled = new Rectangle( this.x, this.y, this.width, this.height );
        if ( scale !== 1 ) {
            scaled.width = this.width * scale;
            scaled.height = this.height * scale;
        }
        return scaled;
    }

    /**
     * Scales the current rectangle up or down maintaining aspect ratio so that it
     * can be placed inside another bounds.
     * Adjusts the x and y positions so that the scaled bounds will always place at the
     * horizontal and vertical center of the given area.
     * This method does not modify the current rectangle - it simply returns a new
     * rectangle with the desired bounds and x, y positions.
     * @param destination - the rectangle which the source will be placed in
     * @return bounds scaled to fit the given area with x, y adjusted for it to be centered.
     */
    public scaleToCenter( destination: IRectangle ): Rectangle {
        const scaledBounds = this.boundsToFit( destination );
        const dx = Math.abs(( destination.width - scaledBounds.width ) / 2 );
        const dy = Math.abs(( destination.height - scaledBounds.height ) / 2 );
        return new Rectangle(
            destination.x + dx,
            destination.y + dy,
            scaledBounds.width,
            scaledBounds.height,
        );
    }

    /**
     * Returns the bounding box rectangle according to the pivot point and angle
     * the pivot point is relative to the top left of the rectangle
     * @param pivot the relative pivot point
     * @param angle the andle in degrees,  0 to 180  or  0 to -180
     * @return A new rectangle which is the bounding box
     */
    public getBoundingBox( pivot: IPoint2D, angle: number ) {
        let theta = angle * Math.PI / 180;
        let x: number;
        let y: number;
        let width: number;
        let height: number;
        if ( theta === 0 ) {
            return this.clone();
        }

        if ( angle > 0 && angle <= 90 ) {
            x = this.x + pivot.x * ( 1 - Math.cos( theta ))
                - Math.sin( theta ) * ( this.height - pivot.y );
            y = this.y + pivot.y * ( 1 - Math.cos( theta ))
                - Math.sin( theta ) * pivot.x;
        } else if ( angle > 90 && angle <= 180 ) {
            x = this.x + pivot.x * ( 1 - Math.cos( theta )) + this.width * Math.cos( theta )
                - Math.sin( theta ) * ( this.height - pivot.y );
            y = this.y + pivot.y * ( 1 - Math.cos( theta )) + this.height * Math.cos( theta )
                - Math.sin( theta ) * pivot.x;
        } else if ( angle < -90 && angle >= -180 ) {
            theta = theta * -1;
            x = this.x + pivot.x * ( 1 - Math.cos( theta )) + this.width * Math.cos( theta )
                - Math.sin( theta ) * pivot.y;
            y = this.y + pivot.y * ( 1 - Math.cos( theta )) + this.height * Math.cos( theta )
                - Math.sin( theta ) * ( this.width - pivot.x );
        } else  { // case ( angle < 0 && angle >= -90 )
            theta = theta * -1;
            x = this.x + pivot.x * ( 1 - Math.cos( theta ))
                - Math.sin( theta ) * pivot.y;
            y = this.y + pivot.y * ( 1 - Math.cos( theta ))
                - Math.sin( theta ) * ( this.width - pivot.x );
        }
        width = this.width * Math.abs( Math.cos( theta )) + this.height * Math.abs( Math.sin( theta ));
        height = this.width * Math.abs( Math.sin( theta )) + this.height * Math.abs( Math.cos( theta ));
        return new Rectangle( x, y, width, height );
    }

    /**
     * Applies the the given transform to the rect and returns the resultant
     * topLeft, topRight, bottomLeft, bottomRight points
     *
     * Ex: If the rectangle is rotated around its center,
     * this function will retunr the tl, tr, bl, br points
     *  ___________
     *  |   tl     |
     *  |  .     tr|
     *  | .     .  |
     *  |bl     .  |
     *  |_____br___|
     */
    public getTransformedPoints( t: ITransform ): { topLeft, topRight, bottomLeft, bottomRight } {
        const topLeftPos = {
            x: { value: 0 },
            y: { value: 0 },
        } as any;
        const topRightPos = {
            x: { value: 1 },
            y: { value: 0 },
        } as any;
        const bottomLeftPos = {
            x: { value: 0 },
            y: { value: 1 },
        } as any;
        const bottomRightPos = {
            x: { value: 1 },
            y: { value: 1 },
        } as any;

        const topLeft = Position.onRect( topLeftPos, this, t );
        const topRight = Position.onRect( topRightPos, this, t );
        const bottomLeft = Position.onRect( bottomLeftPos, this, t );
        const bottomRight = Position.onRect( bottomRightPos, this, t );
        return { topLeft, topRight, bottomLeft, bottomRight };
    }

    /**
     * Returns the scale value and new x y values to fit the rectangle to
     * the given screen
     * @param screen Rectangle
     * @returns
     */
    public fitToFrame( frame: Rectangle ): { scale: number, x: number, y: number } {
        const deltaX = this.width - frame.width;
        const deltaY = this.height - frame.height;
        let scale = 1;
        if ( deltaX / frame.width < deltaY / frame.height && deltaY > 0 ) {
            scale = frame.height / this.height;
        } else if ( deltaX > 0 ) {
            scale = frame.width / this.width;
        }
        return {
            scale,
            x: frame.centerX - this.width / 2 * scale,
            y: frame.centerY - this.height / 2 * scale,
        };
    }

    public toJSON() {
        return {
            top: this.top,
            left: this.left,
            width: this.width,
            height: this.height,
        };
    }

    public getIntersectionPercentage( other: IRectangle ): number {
        const intersectionX = Math.max( this.x, other.x );
        const intersectionY = Math.max( this.y, other.y );
        const intersectionWidth = Math.min( this.x + this.width, other.x + other.width ) - intersectionX;
        const intersectionHeight = Math.min( this.y + this.height, other.y + other.height ) - intersectionY;

        if ( intersectionWidth > 0 && intersectionHeight > 0 ) {
          const intersectionArea = intersectionWidth * intersectionHeight;
          const area = other.width * other.height;
          return ( intersectionArea / area ) * 100;
        } else {
          return 0;
        }
    }
}
