import { TEXT_SHAPE_DEF_ID } from './../../interaction/text-interaction-handler';
import { Injectable } from '@angular/core';
import * as Carota from '@creately/carota';
import { Rectangle } from '@creately/createjs-module';
import { Command, CommandScenario, Tracker } from 'flux-core';
import {
    DEFUALT_TEXT_STYLES,
    ITextContent, ShapeType,
    TEXT_PADDING_HORIZONTAL,
    DEFAULT_HIGHTLIGHTED_COLORS } from 'flux-definition';
import { AbstractShapeModel, TextFormatter, ShapeTextDataModel } from 'flux-diagram-composer';
import { merge, isEqual } from 'lodash';
import { empty } from 'rxjs';
import { TiptapDocumentsManagerShapeText } from '../../../base/ui/text-editor/tiptap-documents-manager-shape-text.cmp';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import * as innerText from '@creately/inner-text';
import { ConnectorModel } from '../../../base/shape/model/connector.mdl';

/**
 * This ApplyText command is to update the text of shapes or connectors.
 * The data requred for this command are the shapeId, textId and the text
 * value to update the text. The textId is optional and if it's not provided
 * the primary text will be considered.
 */
@Injectable()
@Command()
export class ApplyText extends AbstractDiagramChangeCommand {
    public static get dataDefinition(): {}  {
        return {
            shapeId: true, // The id of the shape
            textId: true, // The id of the text to be updated, can be new id
            text: false, // @deprecated
            html: false, // html content
            content: true, // The text content
            positionOnConnector: false, // The position to place the the created text, for connector only
            rendering: false,
            height: false,
        };
    }

    /**
     * Prepares the data necessary for update the text
     */
    public prepareData() {
        this.resultData = this.data;
        if ( !this.data || !this.data.shapeId || !this.data.textId ) {
            return empty();
        }
        const shape = this.changeModel.shapes[this.data.shapeId];
        if ( !shape || shape.isLocked ) {
            return;
        }

        const text = shape.getTextExisting( this.data.textId );

        if ( text && this.data.rendering !== undefined ) {
            text.rendering  = this.data.rendering;
        }
        if ( !text && this.data.content !== undefined ) {
            Tracker.track( 'text.addnew.click', { value1: 'in-shape' });
            this.createText( shape, this.data.textId, this.data.text, this.data.content, this.data.html );
        } else if (( this.data.rendering !== 'tiptapCanvas' && this.data.rendering !== 'dom' )
            && !isEqual( text.content, this.data.content )) {
            let content = this.data.content;
            if ( text.fixedFormat ) {
                content = ( this.data.content as ITextContent[]).map( run => merge( run, text.fixedFormat ));
                this.data.text = ( new TextFormatter()).apply( this.data.text,
                    { indexStart: 0, indexEnd: null, styles: text.fixedFormat },
                );
            }
            delete text.html;
            this.updateText( shape, this.data.textId, this.data.text, content );
        } else if (( this.data.rendering === 'tiptapCanvas' || this.data.rendering === 'dom' )
            && this.data.html !== ( text as any ).html ) {
            const wasEmpty =  innerText( text.html || '' ).trim() === '';
            ( text as any ).html  = this.data.html;
            // Added just for tracking
            /* istanbul ignore if  */
            if ( this.eventData.scenario === CommandScenario.EXECUTE ) {
                const isEmpty = innerText( text.html || '' ).trim() === '';
                if ( wasEmpty && !isEmpty ) {
                    if ( shape.defId === TEXT_SHAPE_DEF_ID ) {
                        Tracker.track( 'text.addnew.click', { value1: 'workspace' });
                    } else {
                        Tracker.track( 'text.addnew.click', { value1: 'in-shape' });
                    }
                } else {
                    Tracker.track( 'text.edit.change' );
                }
                text.content = [];
            }
        } else {
            if ( this.eventData.scenario === CommandScenario.EXECUTE ) {
                TiptapDocumentsManagerShapeText.textRenderingState.next({
                    shapeId: this.data.shapeId,
                    state: 'ignored',
                    textId: this.data.textId,
                });
            }
        }

        if ( text instanceof ShapeTextDataModel ) {
            if ( text.isAlignable()) {
                const alignX = TiptapDocumentsManagerShapeText.getTextAlignment(
                    this.changeModel.id,
                    shape.id,
                    text.id,
                );
                const pos = text.getPositionForAlign( alignX );
                text.xType = pos.x.type;
                text.x = pos.x.value;
                text._alignX = alignX;
            }
        }
        const textMdl = shape.texts[ this.data.textId ] as any;
        if ( this.data.height !== undefined ) {
            textMdl.height  = this.data.height;
        }
        if ( this.data.width !== undefined ) {
            if ( shape.type === ShapeType.Connector ) {
                /**
                 * Adding a padding here prevents the rich-text component from drawing the background
                 * to the correct size. Not adding anything causes text wrap and text is split to different lines
                 * Adding 1 so the background size is correct and there is not text wrapping
                 */
                textMdl.width  = this.data.width + 1;
            } else {
                textMdl.width  = this.data.width;
            }
        }
    }

    protected calculateBounds( shape: AbstractShapeModel, content: ITextContent[]): Rectangle {
        let bounds;
        if ( shape.type === ShapeType.Connector ) {
            bounds = Carota.bounds( content, 10000, DEFUALT_TEXT_STYLES );
        } else {
            bounds = Carota.bounds( content, shape.bounds.width - 2 * TEXT_PADDING_HORIZONTAL, DEFUALT_TEXT_STYLES );
        }
        return bounds;
    }

    private createText(
        shape: AbstractShapeModel, textId: string, value: string, content: ITextContent[], html: string ): void {
            const text: any = {
                id: textId,
                value: value,
                html,
                content: content,
                customCreated: true,
                primary: shape.primaryTextModel === undefined ? true : false,
            };
            if ( shape.type === ShapeType.Connector && this.data.positionOnConnector ) {
                text.pos = this.data.positionOnConnector;
            }
            const bounds = this.calculateBounds( shape, content );
            text.width = bounds.width;
            text.height = bounds.height;
            shape.texts[textId] = text;
            this.updateConnectorText( shape, textId );
    }

    private updateText( shape: AbstractShapeModel, textId: string, value: string, content: ITextContent[]): void {
        const text = shape.texts[textId];
        text.value = value;
        const wasEmpty = text.plainText === '';
        text.content = content;
        const isEmpty = text.plainText === '';

        this.updateConnectorText( shape, textId );

        // Added just for tracking
        /* istanbul ignore if  */
        if ( this.eventData.scenario === CommandScenario.EXECUTE ) {
            if ( wasEmpty && !isEmpty ) {
                if ( shape.defId === TEXT_SHAPE_DEF_ID ) {
                    Tracker.track( 'text.addnew.click', { value1: 'workspace' });
                } else {
                    Tracker.track( 'text.addnew.click', { value1: 'in-shape' });
                }
            } else {
                Tracker.track( 'text.edit.change' );
            }
        }
    }

    private updateConnectorText( shape: AbstractShapeModel, textId: string ): void {
        // If connector and text are default color, change text color to white for highlighted connectors
        if ( shape.isConnector() && ( shape as ConnectorModel ).isHighlighted ) {
            const text = shape.texts[textId];
            for ( const textSegment of text.content ) {
                textSegment.backgroundColor = shape.style.lineColor;
                if ( shape.style.lineColor === '#1a1a1a' && textSegment.color === DEFUALT_TEXT_STYLES.color ) {
                    textSegment.color = DEFAULT_HIGHTLIGHTED_COLORS.color;
                }
                if ( textSegment.color === DEFUALT_TEXT_STYLES.color  &&
                    ( shape as any ).style?.textColor !== DEFUALT_TEXT_STYLES.color ) {
                        textSegment.color = ( shape as any ).style.textColor;
                    }
            }
        }
    }

}

Object.defineProperty( ApplyText, 'name', {
    value: 'ApplyText',
});
