<div class="notifications-tab-container">
    <div class="notifications-list-container" *ngIf="( notifications | async )?.length > 0">
        <perfect-scrollbar class="col-menu-scrollbar" (psYReachEnd)="loadMore()">
            <ng-container *ngFor="let notification of (notifications| async); let key = index;">
                <notification-list-item [notification]="notification" *ngIf="!showAsRecentUpdateItem; else notificationRecentUpdateItem"></notification-list-item>
                <ng-template #notificationRecentUpdateItem>
                    <notification-recent-update-item [notification]="notification" [class.no-margin]="key === 0"></notification-recent-update-item>
                </ng-template>
            </ng-container>
        </perfect-scrollbar>
    </div>
    <div class="notifications-list-container" *ngIf="!(( notifications | async )?.length > 0 )">
        <message-card [title]="msgTitle" [body]="msgBody" [titleIcon]="emptyStateIcon">
        </message-card>
        <div class="globle-notification-no-notification-container fx-margin-bottom-30">
            <div class="globle-notification-no-notification-text" translate>GLOBAL_NOTIFICATION.NO_NOTIFICATION</div>
            
        <button class="btn-link btn-medium nu-ic-help fx-center" >
            <svg class="nu-icon nu-icon-xsmall fx-margin-right-5 help-btn">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-help"></use>
            </svg>
            <a translate href="https://creately.com/help/sharing-and-collaboration/notifications/" target="_blank">ALERTS.EMPTY_SCREEN.LEARN_MORE</a>
        </button>
        </div>
    </div>
</div>