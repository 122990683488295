import { mergeAttributes } from '@tiptap/core';
import { INodeView } from './node-view.i';
import { Plugin } from 'prosemirror-state';
import { getTextDirection } from 'tiptap-text-direction';

/**
 * Tiptap nodeview to insert a child editor inside tiptap
 */
export class ChildEditorNodeView implements INodeView {

    /**
     * Returns the nodeView that renders data items in tiptap editor
     * @param injector
     * @param viewCR ViewContainerRef to render angular components
     * @returns
     */
    public static create() {
        const instance = new ChildEditorNodeView();
        return instance;
    }

    public name = 'tiptapChildEditor';
    public group = 'block';
    public content = 'block+';
    public atom = true;
    public selectable = false;
    public draggable = false;
    public isolating = true;
    public allowGapCursor = true;

    public subs = [];

    protected constructor(
    ) {
    }

    public destroy() {
        while ( this.subs.length > 0 ) {
            this.subs.pop().unsubscribe();
        }
    }

    public addOptions = () => ({
        HTMLAttributes: {},
    })

    public addAttributes = () => ({
        entityid: {
            default: '',
        },
        shapeId: {
            default: '',
        },
        textId: {
            default: '',
        },
    })

    public parseHTML = () => [
          {
            tag: 'tiptap-child-editor-node',
          },
          {
            tag: 'div[data-type="tiptap-child-editor-node"]',
          },
    ]

    public renderHTML = function ( this, {  HTMLAttributes }) {
        return [ 'tiptap-child-editor-node', mergeAttributes( this.options.HTMLAttributes, HTMLAttributes ), 0 ];
    };

    public addNodeView = () =>
        ({
        editor,
        node,
        getPos,
        HTMLAttributes,
        decorations,
        extension,
        }) => {
            const { shapeId, textId } = node.attrs;
            const item = document.createElement( 'div' );
            item.classList.add( 'tiptap-child-editor' );
            item.setAttribute( 'data-type', 'tiptap-child-editor-node' );
            item.setAttribute( 'data-editor-id', `${shapeId}-${textId}` );

            const bounds = item.getBoundingClientRect();
            item.style.minHeight = (( window.innerHeight / 2 ) - bounds.top ) + 'px';

            const content = document.createElement( 'div' );
            content.classList.add( 'tiptap-child-editor-content' );
            content.setAttribute( 'data-type', 'tiptap-child-editor-content' );
            item.append( content );

            Object.entries( HTMLAttributes ).forEach(([ key, value ]) => {
                item.setAttribute( key, value as any );
            });
            return {
                dom: item,
                getPos,
                contentDOM: content,
                ignoreMutation: ( mutation: MutationRecord ) =>
                  !item.contains( mutation.target ) || item === mutation.target,
            };

    }

    public selectAllInsideNde = () => {};

    public addCommands = () => ({
        addChildEditorNode: attrs => ({ commands }) => {
            commands.wrapIn( 'tiptapChildEditor', attrs );
        },
        liftChildEditorNode: () => ({ commands }) => commands.lift( 'tiptapChildEditor' ),
    })

    addKeyboardShortcuts = function ( this ) {
        const keepCaretInside = key => {
            if ( document.querySelector( 'tiptap-slash-cmd-list-cmp' ) || document.querySelector( 'tiptap-emoji-list-cmp' )) {
                return false;
            }
            const $posAnchor = this.editor.view.state.selection.$anchor;
            const $posHead = this.editor.view.state.selection.$head;
            const view = this.editor.view;
            for ( let d = $posAnchor.depth; d > 0; d-- ) {
                const node = $posAnchor.node( d );
                if ( node.type.name === 'tiptapChildEditor' ) {
                    const id = node.attrs.id;
                    const domNode = this.editor.view.dom.querySelector( `div[data-id="${id}"]` );
                    const from = this.editor.view.posAtDOM( domNode as HTMLElement, 0 ) + 1;
                    const to = from + node.nodeSize - 4;

                    const selectedText = this.editor.view.state.doc.textBetween( from, to, '\n' );
                    const isRTL = getTextDirection( selectedText ) === 'rtl' ;
                    if ( isRTL ) {
                        if ( key === 'ArrowRight' && Math.min( $posAnchor.pos, $posHead.pos ) === from ) {
                            this.editor.commands.setTextSelection( from );
                            return true;
                        }
                        if ( key === 'ArrowLeft' && Math.max( $posAnchor.pos, $posHead.pos ) === to ) {
                            this.editor.commands.setTextSelection( to );
                            return true;
                        }
                        const coords = view.coordsAtPos( $posAnchor.pos );
                        const threshold = 20;
                        if ( key === 'ArrowUp' ) {
                            const checkPos =
                                view.posAtCoords({ left: coords.left, top: coords.top - ( threshold - 5 ) });
                            if ( !checkPos ||  checkPos.pos + 1 < from ) {
                                this.editor.commands.setTextSelection( from );
                                return true;
                            }
                        }
                        if ( key === 'ArrowDown' ) {
                            const checkPos = view.posAtCoords({ left: coords.left, top: coords.bottom + threshold });
                            if ( !checkPos || checkPos.pos + 1 > to ) {
                                this.editor.commands.setTextSelection( to );
                                return true;
                            }
                        }
                    } else {
                        if ( key === 'ArrowLeft' && Math.min( $posAnchor.pos, $posHead.pos ) === from ) {
                            this.editor.commands.setTextSelection( from );
                            return true;
                        }
                        if ( key === 'ArrowRight' && Math.max( $posAnchor.pos, $posHead.pos ) === to ) {
                            this.editor.commands.setTextSelection( to );
                            return true;
                        }

                        const coords = view.coordsAtPos( $posAnchor.pos );
                        const threshold = 20;
                        if ( key === 'ArrowUp' ) {
                            const checkPos =
                                view.posAtCoords({ left: coords.left, top: coords.top - ( threshold - 5 ) });
                            if ( !checkPos ||  checkPos.pos + 1 < from ) {
                                this.editor.commands.setTextSelection( from );
                                return true;
                            }
                        }
                        if ( key === 'ArrowDown' ) {
                            const checkPos = view.posAtCoords({ left: coords.left, top: coords.bottom + threshold });
                            if ( !checkPos || checkPos.pos + 1 > to ) {
                                this.editor.commands.setTextSelection( to );
                                return true;
                            }
                        }
                    }
                    break;
                }
            }
            return false;
        };
        return {
            'Mod-a': () => {
                const $pos = this.editor.view.state.selection.$anchor;
                const dom = this.editor.view.dom;
                for ( let d = $pos.depth; d > 0; d-- ) {
                    const node = $pos.node( d );
                    if ( node.type.name === 'tiptapChildEditor' ) {
                        const id = node.attrs.id;
                        const domNode = dom.querySelector( `div[data-id="${id}"]` );
                        if ( domNode.innerText === '\n' ) {
                            return true;
                        }
                        const pos = this.editor.view.posAtDOM( domNode as HTMLElement, 0 ) + 1;
                        this.editor.commands.setTextSelection({ from: pos, to: pos + node.nodeSize - 4 });
                        return true;
                    }
                }
                return false;
            },
            'ArrowUp': () => keepCaretInside( 'ArrowUp' ),
            'ArrowDown': () => keepCaretInside( 'ArrowDown' ),
            'ArrowLeft': () => keepCaretInside( 'ArrowLeft' ),
            'ArrowRight': () => keepCaretInside( 'ArrowRight' ),
            'Backspace': () => {
                const { selection } = this.editor.state;
                const { $anchor } = selection;

                const $pos = this.editor.view.state.selection.$anchor;
                let primaryTextNode;
                let detailsSummary;
                for ( let d = $pos.depth; d > 0; d-- ) {
                    const node = $pos.node( d );
                    if ( node.type.name === 'tiptapChildEditor' ) {
                        if ( node.firstChild.type.name  === 'primaryTextNode' ) {
                            primaryTextNode = node.firstChild;
                            detailsSummary = primaryTextNode?.firstChild;
                            break;
                        }
                        return false;
                    }
                }

                if ( $anchor?.nodeBefore === primaryTextNode ) {
                    return true;
                }
                if ( $anchor?.nodeAfter === primaryTextNode ) {
                    return true;
                }
                if ( $anchor?.parent === detailsSummary  &&
                    ( $anchor.parent?.content.size === 0 || $anchor.parentOffset === 0 )) {
                        return true;
                }
                if ( $anchor?.parent === detailsSummary && !$anchor?.parent?.attrs.contenteditable ) {
                    return true;
                }
                return false;
            },
        };
    };

    addProseMirrorPlugins = () => [
        new Plugin({
          props: {
            handleDOMEvents: {
                keydown: ( view, event ) => {
                    const $pos = view.state.selection.$anchor;
                    let insideChildEditor = false;
                    for ( let d = $pos.depth; d > 0; d-- ) {
                        const node = $pos.node( d );
                        if ( node.type.name === 'tiptapChildEditor' ) {
                            insideChildEditor = true;
                            break;
                        }
                    }
                    if ( !insideChildEditor ) {
                        event.preventDefault();
                    }
                    return false;
                },
            },
          },
        }),
      ]

}

declare module '@tiptap/core' {
    // tslint:disable-next-line:interface-name
    interface Commands<ReturnType> {
        tiptapChildEditor: {
        /**
         * Set a details node
         */
        addChildEditorNode: ( attrs ) => ReturnType,
        liftChildEditorNode: () => ReturnType,
      };
    }
  }
