import { IMessageJson } from './message-json';

/**
 * Defines errors that are caused by and as part of server messages.
 * Directly relates to {@link Message} object and anything related to
 * it's interation
 *
 * @author  hiraash
 * @since   2016-04-26
 *
 */
export class MessageError extends Error {
    constructor( message: string ) {
        super()/* istanbul ignore next */;
        this.message = message;
        this.name = 'MessageError';
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, MessageError.prototype );
    }
}

/**
 * Defines errors were returned by Neutrino. Contains a error code
 * and always defines an error that occured on the server or on the
 * channel
 *
 * @author  hiraash
 * @since   2016-04-26
 *
 */
export class MessageResultError extends MessageError {

    protected _code: number;

    protected _message: IMessageJson;

    constructor( code: number, errorMessage: string, message: IMessageJson ) {
        super( errorMessage )/* istanbul ignore next */;
        this.name = 'MessageResultError';
        this._code = code;
        this._message = message;
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, MessageResultError.prototype );
    }

    public get code() {
        return this._code;
    }

    public get result(): IMessageJson {
        return this._message;
    }
}

/**
 * InvalidMessageError defines the error caused when Message is invalid.
 * eg. error caused when Message does not have a mandatory field like message id
 *
 * @author  mehdhi
 * @since   2016-04-06
 *
 */
export class InvalidMessageError extends MessageError {
    constructor( message: string ) {
        super( message )/* istanbul ignore next */;
        this.name = 'InvalidMessageError';
        // NOTE: set correct proto when extending std classes https://git.io/v7UuA
        Object.setPrototypeOf( this, InvalidMessageError.prototype );
    }
}
