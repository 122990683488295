import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NeutrinoConnection } from '../../connection/neutrino-connection.svc';
import { Message } from '../../message/message.mdl';
import { ICommandResult, SendExecutionStep } from './exec-step-send';

/**
 * SendWsExecutionStep is a command execution step which will send
 * the command data to the server via WebSocket.
 */
export class SendWsExecutionStep extends SendExecutionStep {
    /**
     * Implement the sendMessage abstract method.
     */
    protected sendMessage( message: Message ): Observable<ICommandResult> {
        const connection = this.injector.get( NeutrinoConnection );
        return connection.send( message ).pipe(
            map( res => res.ppld ),
        );
    }
}

Object.defineProperty( SendWsExecutionStep, 'name', {
    value: 'SendWsExecutionStep',
});
