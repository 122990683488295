import { Logger } from 'flux-core';
import { DataStore } from 'flux-store';
import { UserLocator } from 'flux-user';
import { InitializationChainStatus } from 'flux-core';
import { Observable, combineLatest } from 'rxjs';
import { UserModel } from 'flux-user';
import { take, map } from 'rxjs/operators';
import { ResourceModel } from '../../model/resource.mdl';
import { DiagramInfoModel } from '../model/diagram-info.mdl';
import { CollaboratorType } from '../../collab/model/collaborator.mdl';

/**
 * An enum whichs values are used to keep track of the level
 * of a particular permission a user has on a resource.
 */
export enum PermissionStatus {

    /**
     * When user has adequate permission to access the resource
     */
    GRANTED,

    /**
     * When the user has access to the resource via some permission level,
     * but it's below the required level
     */
    BELOW_REQUIREMENT,

    /**
     * Access to the resource is denied due to lack of permission
     */
    DENIED,
}

/**
 * This is an abstraction for responsibilities which check the status
 * of permissions in the initialization sequence( namely privacy and role
 * permission). All common functionality that is required by these
 * responsibilities are included here.
 *
 * @author  Ramishka
 * @since   2018-04-27
 */
export abstract class AbstractPermissionResponsibility {

    public abstract name: string;

    constructor( protected logger: Logger,
                 protected dataStore: DataStore,
                 protected userLocator: UserLocator ) {}

    /**
     * Determines the level of permission for the current user on a given resource.
     * This function will emit a value which maps to {@link PermissionStatus}, depending
     * on the level of permission the user has on the resource.
     * @param status - current status of the sequence
     * @return  Observable which emits a value of {@link PermissionStatus}
     */
    public checkState( status: InitializationChainStatus ): Observable<PermissionStatus> {
        this.logger.debug( this.name + ' checkState' );
        return combineLatest(
            this.dataStore.findOne( DiagramInfoModel, { id: status.input.resourceId }).pipe( take( 1 )),
            this.userLocator.getUserData().pipe( take( 1 )),
        ).pipe( map(([ diagram, user ]) => this.checkPermission( status, diagram, user )));
    }

    /**
     * Contains the actual permission check. This must be implemented by
     * the concrete responsibility in such way that so that it checks the
     * relevant permission and returns the level of permission the user has
     * on the resource.
     * @param status - current status of the sequence
     * @param resource - resource model of the resource that is being viewed or edited
     * @param user - user model of the current user
     * @return permission status which maps to a value of {@link PermissionStatus}
     */
    protected abstract checkPermission(
        status: InitializationChainStatus,
        resource: ResourceModel,
        user: UserModel ): PermissionStatus;

    /**
     * Checks the role permission status of the user on a given resource
     * based on the users role in the said resource and the action the
     * user is trying perform on the resource (view / edit).
     * @param status - current sequence status
     * @param role - users role in the resources
     * @return - role permission status
     */
    protected checkRolePermission( status: InitializationChainStatus, role: number ): PermissionStatus {
        const s = this.getAction( status );
        if (( s.isViewing && role <= CollaboratorType.REVIEWER ) ||
            ( s.isEditing && role <= CollaboratorType.EDITOR )) {
            return PermissionStatus.GRANTED;
        } else if ( s.isEditing && role > CollaboratorType.EDITOR ) {
            return PermissionStatus.BELOW_REQUIREMENT;
        } else {
            return PermissionStatus.DENIED;
        }
    }

    /**
     * Derives the diagram action the user is trying to perform
     * from the chain status and returns a map with boolean values
     * for easy access.
     * @param status chain status
     */
    protected getAction( status: InitializationChainStatus ) {
        return {
            isViewing: ( status.input.action === 'view' ),
            isEditing: ( status.input.action === 'edit' ),
        };
    }

    /**
     * Get the widest permission status of given permission status.
     * @param ps1
     * @param ps2
     * @returns
     */
    protected getMaxPermissionStatus( ps1: PermissionStatus, ps2: PermissionStatus ) {
        if ( ps1 === undefined && ps2 === undefined ) {
            return PermissionStatus.DENIED;
        }
        if ( ps1 === undefined ) {
            return ps2;
        } else if ( ps2 === undefined ) {
            return ps1;
        }
        return ps1 <= ps2 ? ps1 : ps2;
    }
}

