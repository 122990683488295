<div class="modal-window-container fx-cover fx-center-all" #window (click)="closeOnOverlayClick($event)">
    <div #windowInner>
        <div class="uppy-container" (click)="onContainerClick($event)"></div>
        <div class="file-importer-close-btn">
            <button class="btn-small" (click)="closeWindow()"
                [trackAction]="{ event: 'dialog.image.import.upload.cancel' }">
                <svg class="nu-icon nu-icon-med nu-ic-close"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use></svg>
            </button>
        </div>
        <div *ngIf="( shapeImage && shapeImage.hash && !shapeImage.removed && !isFilesUploded )" class="file-importer-preview">
            <img [src]="getImageUrl( shapeImage.hash )" >
            <button class="btn-small" (click)="removePreviewImage()">
                <svg class="nu-icon nu-icon-med nu-ic-close"><use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use></svg>
            </button>
        </div>      
    </div>
</div>