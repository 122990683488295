import { AbstractShapeModel, ShapeDataModel } from 'flux-diagram-composer';
import { Injectable } from '@angular/core';
import { Command, Layouting, Line } from 'flux-core';
import { AbstractDiagramChangeCommand } from './abstract-diagram-change-command.cmd';
import { ShapeModel } from 'apps/nucleus/src/base/shape/model/shape.mdl';
import { isEmpty } from 'lodash';
import { Proxied } from '@creately/sakota';
import { DiagramModel } from 'apps/nucleus/src/base/diagram/model/diagram.mdl';
import { forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';

/**
 * This ContainerLayout command is to calculate and re layout the region base containers
 */
@Injectable()
@Command()
export class DiagramContainersReLayout extends AbstractDiagramChangeCommand {

    private containerResizePadding = 10;
    /**
     * Prepares the data necessary for update the text
     */
    public prepareData() {
        const obs: any[] = [ of({}) ];
        for ( const id in this.changeModel.shapes ) {
            const shape = this.changeModel.shapes[id] as ShapeModel;
            if ( !isEmpty( shape.containerRegions )) {
                const containerRegionIds = Object.keys( shape.containerRegions );
                obs.push( this.layoutChildren( this.changeModel, shape, containerRegionIds ));
            }
        }
        return forkJoin( ...obs );
    }

    /**
     * Re arrange the shapes in regions
     * @param diagram diagram
     * @param container container model
     * @param regionIds Region ids to run the layouting
     * @param scenario
     */
    protected layoutChildren(
    diagram: Proxied<DiagramModel>,
    container: ShapeDataModel,
    regionIds: string[],
    resizeContainer = true,
        ) {
            const obs: any[] = [ of({}) ];
            regionIds.forEach( rid => {
                const region = container.containerRegions[ rid ];
                if ( region && region.shapes && !isEmpty( region.shapes )) {
                    const shapesInRegion = Object.keys( region.shapes )
                        .map( id => diagram.shapes[ id ] as ShapeDataModel )
                        .filter( shapes => shapes );
                    const bounds = {
                        x: region.x + container.x,
                        y: region.y + container.y,
                        width: region.width,
                        height: region.height,
                    };
                    obs.push( fromPromise( Layouting.apply(
                        diagram,
                        shapesInRegion,
                        bounds,
                        region.layoutingData,
                        undefined,
                        false,
                    )));
                }
            });
            return forkJoin( ...obs ).pipe( tap({
                complete: () => {
                if ( container.children ) {
                    for ( const key in container.children ) {
                        this.updateRelativeTranslate( container, diagram.shapes[ key ]);
                    }
                }
                if ( resizeContainer ) {
                        // changes done in resizeContainerAfterLayouting method in this binder are applicable
                        // only for the basic containers, for complex shapes, resizeContainerAfterLayouting
                        // hook should be implemented in the shape logic class
                        if (( container as any ).resizeContainerAfterLayouting ) {
                            ( container as any ).resizeContainerAfterLayouting( diagram, container );
                        } else  {
                            this.resizeContainerAfterLayouting( diagram, container );
                        }
                    }
                },
            }));
        }

    /**
     * Update the relative x, y and angle of the given child in the contianer
     */
    protected updateRelativeTranslate( container: ShapeDataModel, child: AbstractShapeModel ) {
        const containerTb = container.defaultBounds.getTransformedPoints( container.transform );
        const childShape = child as ShapeDataModel;
        const childTb =  childShape.defaultBounds.getTransformedPoints( childShape.transform );
        const relativeAngle = childShape.angle - container.angle;
        const baseXPoint = Line
            .from( containerTb.topLeft.x, containerTb.topLeft.y,
                containerTb.bottomLeft.x, containerTb.bottomLeft.y )
            .perpendicularTo( childTb.topLeft );
        const relativeX = Line.from( baseXPoint.x, baseXPoint.y, childTb.topLeft.x, childTb.topLeft.y ).length();

        const baseYPoint = Line
            .from( containerTb.topLeft.x, containerTb.topLeft.y, containerTb.topRight.x, containerTb.topRight.y )
            .perpendicularTo( childTb.topLeft );
        const relativeY = Line.from( baseYPoint.x, baseYPoint.y, childTb.topLeft.x, childTb.topLeft.y ).length();
        if ( container.children[ child.id  ]) {
            container.children[ child.id  ].relativeX = relativeX;
            container.children[ child.id  ].relativeY = relativeY;
            container.children[ child.id  ].relativeAngle = relativeAngle;
        }
    }

    /**
     * This method updates the container size to contain all the children
     */
    protected resizeContainerAfterLayouting( diagramModel: Proxied<DiagramModel>, container: ShapeDataModel ) {
        const oldSize = container.bounds;
        // FIXME: Sometimes container.children has shapes ids which are not present in diagram.shapes.
        const children = Object.keys( container.children || {})
            .filter( id => !!diagramModel.shapes[ id ]);
        if ( children.length ) {
            const newSize = diagramModel.getBounds( children );
            newSize.absorb( oldSize );
            const widthChange = newSize.width - oldSize.width;
            const heightChange = newSize.height - oldSize.height;
            if ( widthChange > 0 ) {
                const expectedWidth = container.width + widthChange
                    + this.containerResizePadding;
                container.scaleX = expectedWidth / container.defaultBounds.width;
            }
            if ( heightChange > 0 ) {
                const expectedHeight = container.height + heightChange
                    + this.containerResizePadding;
                container.scaleY = expectedHeight / container.defaultBounds.height;
            }
        }
    }
}

Object.defineProperty( DiagramContainersReLayout, 'name', {
    value: 'DiagramContainersReLayout',
});
