<ng-template #headingBlock>
    <span class="abstract-notification-heading body body--bold">{{ heading }}</span>
</ng-template>

<ng-template #descriptionBlock>
    <span *ngIf="description"
    [ngStyle]="!!backgroundColor ? {'color': '#ffffff' } : {}"
    class="abstract-notification-description caption" [innerHTML]="description | safeHTML"></span>
    <span *ngIf="descriptionSub1"
    [ngStyle]="!!backgroundColor ? {'color': '#ffffff' } : {}"
    class="abstract-notification-description caption" [innerHTML]="descriptionSub1 | safeHTML"></span>
    <span *ngIf="descriptionSub2"
    [ngStyle]="!!backgroundColor ? {'color': '#ffffff' } : {}"
    class="abstract-notification-description caption" [innerHTML]="descriptionSub2 | safeHTML"></span>
    <span *ngIf="descriptionSub3"
    [ngStyle]="!!backgroundColor ? {'color': '#ffffff' } : {}"
    class="abstract-notification-description caption" [innerHTML]="descriptionSub3 | safeHTML"></span>
    <span *ngIf="descriptionText"
    [ngStyle]="!!backgroundColor ? {'color': '#ffffff' } : {}"
    class="abstract-notification-description caption" [innerHTML]="descriptionText | async | safeHTML"></span>
</ng-template>

<div class="abstract-notification-container fx-pointer-events-auto fx-flex"  [ngStyle]="getBackgroundColor()"
    [ngClass]="getNgClass()"
    (click)="handleNotificationClick($event)">

    <div class="abstract-notification-content" [ngStyle]="isUserFollow && !!notificationAction ? {'cursor': 'pointer'} : {}">
        <div class="abstract-notification-title-container fx-flex fx-align-center"
            [class.abstract-notification-has-heading]="!!heading">

            <div *ngIf="!isUserFollow" class="abstract-notification-icon-container">
                <svg *ngIf="!user"
                    [ngStyle]="!!backgroundColor ? {'color': '#ffffff' } : {}"
                    class="nu-icon abstract-notification-icon"
                    [ngClass]="'nu-ic-' + icon">
                    <use [attr.xlink:href]="iconUrl()"></use>
                </svg>
                <user-image
                    *ngIf="user"
                    imageSize="small"
                    [hasImage]="user.hasImage"
                    [imagePath]="user.image"
                    [userColor]="user.getColor()"
                    [userInitials]="user.initial"
                    [userRegistered]="user.isRegistered"
                    [userOnlineStatus]="true">
                </user-image>
            </div>

            <ng-container *ngIf="heading; then headingBlock else descriptionBlock"></ng-container>
        </div>

        <div class="abstract-notification-description-container"
            *ngIf="heading && ( description || descriptionText )">
            <ng-container *ngTemplateOutlet="descriptionBlock"></ng-container>
        </div>

        <div *ngIf="!isUserFollow" class="abstract-notification-actions-container">
            <button
                class="btn-small btn-primary abstract-notification-button"
                *ngIf="buttonOneText && buttonOneAction"
                (click)="handleButtonOneClick($event)">
                    {{ buttonOneText }}
            </button>
            <button
                class="btn-small btn-secondary abstract-notification-button secondary"
                *ngIf="buttonTwoText && buttonTwoAction"
                (click)="handleButtonTwoClick($event)">
                    {{ buttonTwoText }}
            </button>
            <a
                class="btn-link abstract-notification-link"
                *ngIf="linkText && link"
                [attr.href]="link"
                target="_blank"
                (click)="handleLinkClick()">
                    {{ linkText }}
            </a>
        </div>
    </div>

    <button class="fx-button abstract-notification-close-button"
        (click)="dismissNotification(true, $event)">
        <svg class="nu-icon nu-ic-close" [ngStyle]="!!backgroundColor ? {'color': '#ffffff' } : {}">
            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
        </svg>
    </button>
</div>
