import { Injectable } from '@angular/core';
import { IResponsibility, Logger } from 'flux-core';
import { DataStore } from 'flux-store';
import {
    InitializationChainStatus,
    IChainOutcome,
} from 'flux-core';
import { UserLocator } from 'flux-user';
import { UserModel } from 'flux-user';
import { find } from 'lodash';
import { AbstractPermissionResponsibility, PermissionStatus } from './abstract-permission-responsibility';
import { Router } from '@angular/router';
import { ResourceModel } from '../../model/resource.mdl';
import { DiagramInfoModel } from 'flux-diagram/models';

/**
 * This is an {@link IResponsibility} used in the initialization sequence.
 * Primary state defined by this responsibility is the level of role permission
 * a user has a on a given resource.
 *
 * For further information on what a responsibility is and how it's used
 * in a sequence, refer to {@link IResponsibility} and {@link ChainSequenceController}
 * documentation.
 *
 * @author  Ramishka
 * @since   2017-02-04
 */
@Injectable()
export class RolePermissionResponsibility extends AbstractPermissionResponsibility implements IResponsibility  {

    public name: string = 'RolePermissionResponsibility';

    constructor( protected logger: Logger,
                 protected dataStore: DataStore,
                 protected userLocator: UserLocator,
                 protected router: Router ) {
        super( logger, dataStore, userLocator );
    }

    /**
     * Determines the next resposibility in the sequence
     * @param status - current status of the sequence
     * @return an array with names of responsibilities that come next in sequence
     */
    public nextResponsibility( status: InitializationChainStatus ): string [] {
        return [];
    }

    /**
     * Returns a result if a result can be determined.
     * @param status - current status of the sequence
     * @return - result if a result can be determined based on current state
     */
    public result( status: InitializationChainStatus ): IChainOutcome {
        const s = this.getStates( status );
        const { resourceId, action } = status.input;
        if ( s.isRolePermGranted ) {
            return { type: 'complete' };
        }
        if ( s.isPermBelowRequirement && !s.isViewing  ) {
            return { type: 'route', action: `${resourceId}/view` };
        }
        return { type: 'route', action: `${resourceId}/${action}-permission-error` };
    }

    /**
     * Checks what level of role permission the user has on the
     * diagram he/she is trying to view or edit.
     * @param status - current status of the sequence
     * @param diagram - diagram model of the diagram attempted to be viewed or edited
     * @param user - user model of the current user
     * @return role permission status
     */
    protected checkPermission(
            status: InitializationChainStatus,
            diagram: ResourceModel,
            user: UserModel ): PermissionStatus {
        const permStatus = this.getCollabPermissionStatus( status, diagram, user );
        if ( diagram instanceof DiagramInfoModel ) {
            const projectPermStatus = this.getCollabPermissionStatus( status, diagram.project, user );
            return this.getMaxPermissionStatus( permStatus, projectPermStatus );
        }
        return permStatus;
    }

    /**
     * Get the access permission status of given resource for given user.
     * @param status
     * @param resource
     * @param user
     * @returns
     */
    protected getCollabPermissionStatus( status: InitializationChainStatus, resource: ResourceModel,
                                         user: UserModel ): PermissionStatus {
        if ( resource.collabs && resource.collabs.length > 0 ) {
            const collab = find( resource.collabs, { id: user.id }); // Current user as a collaborator of diagram
            if ( collab ) {
                return this.checkRolePermission( status, collab.role );
            }
        }
        return PermissionStatus.DENIED;
    }

    /**
     * Returns true if the link is a view url
     * Matches the following:
     *  /resourceId/view
     *  /resourceId/view/
     *  /resourceId/view?.....
     */
    protected isViewURL( resourceId: string, url: string ) {
        if ( !url ) {
            return false;
        }
        const regex = new RegExp( `^(\\/${resourceId}\\/view)(\\/?\\?{0}|\\/?\\?{1}.*)$` );
        return regex.test( url );
    }

    /**
     * Derives the status of role and privacy permissions for the given user
     * and returns a map with the results for easy access
     * @param status - chain status
     */
    private getStates ( status: InitializationChainStatus ) {
        return {
            isRolePermGranted: status.states.RolePermissionResponsibility === PermissionStatus.GRANTED,
            isPermBelowRequirement: status.states.RolePermissionResponsibility === PermissionStatus.BELOW_REQUIREMENT
                || status.states.PrivacyResponsibility === PermissionStatus.BELOW_REQUIREMENT,
            isViewing: this.isViewURL( status.input.resourceId, this.router.url ),
        };
    }
}
